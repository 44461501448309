import React, { useState, useEffect } from 'react';

const NewPublishableJobPage = ({ 
    invoice,
    notes: initialNotes, 
    keywords: initialKeywords, 
    customerName: initialCustomerName, 
    address: initialAddress, 
    customTitlePrompt: initialCustomTitlePrompt, 
    customHtmlPrompt: initialCustomHtmlPrompt 
  }) => {
    const [includePrice, setIncludePrice] = useState(false);
    const [notes, setNotes] = useState(initialNotes);
    const [notesWithoutPrice, setNotesWithoutPrice] = useState(notesWithoutPrice);
    const [keywords, setKeywords] = useState(initialKeywords);
    const [customerName, setCustomerName] = useState(initialCustomerName);
    const [address, setAddress] = useState(initialAddress);
    const [selectedPictures, setSelectedPictures] = useState([]);

    const [customPrompt, setCustomPrompt] = useState(false);
    const [customTitlePrompt, setCustomTitlePrompt] = useState(initialCustomTitlePrompt);
    const [customHtmlPrompt, setCustomHtmlPrompt] = useState(initialCustomHtmlPrompt);

    const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (includePrice) {
      setNotes(notes);
    } else {
      setNotes(notesWithoutPrice);
    }
  }, [includePrice]);

  // useEffect(() => {
  //   console.log(selectedPictures);
  // }, [selectedPictures]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("made it!");

    // #   "published_job_page" => {
    //   #     "notes" => "Job Notes: Removal of tree stump and roots\r\nItem: Stump grind small stump and roots in front flower bed leaving stump 4-6\" below grade. Clean up and backfill. (Mulch removal not included)\r\nItem: Add stump in backyard ",
    //   #     "keywords" => "stump removal, stump grinding, stump poisoning, trunk removal",
    //   #     "customer_name" => "Alayna",
    //   #     "address" => "Riverton, UT",
    //   #     "job_id" => "11921",
    //   #     "invoice_id" => "6318"
    //   #   },
    // #   "attachments" => {
    //   #     "IMG_0232.JPG" => "9270"
    //   #   }, ^^ ABOVE IS THE CORRECT WAY TO NEST THE FORM DATA 

    

    const form_data = {
      published_job_page: {
        invoice_id: invoice.id,
        job_id: invoice?.job?.id,
        notes: notes,
        keywords: keywords,
        customer_name: customerName,
        address: address,
        use_custom_prompt_on_generation: customPrompt,
        custom_title_prompt: customTitlePrompt,
        custom_html_prompt: customHtmlPrompt,
      },
      attachments: getAttachedImages(),
    };

    // debugger;
    console.log(form_data);

    setIsLoading(true);
    try {
      const response = await fetch('/published_job_pages.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(form_data)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Success:', data);
        let publishedJobPageId = data.id;
        document.location.href = `/published_job_pages/${publishedJobPageId}`;
        // setIsLoading(false);
        // Handle success (e.g., redirect to the new job page)
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData);
        setIsLoading(false);
        // Handle error (e.g., display error messages)
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
      // Handle network error
    }
  }

  const getAttachedImages = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"][name^="attachments"]');

    // Initialize an object to store selected images
    const selectedImages = {};

    // Iterate through each checkbox and add it to the object if checked
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        // Assuming the value is in the format "filename:id"
        // const [filename, id] = checkbox.value.split(':');
        
        const filename = checkbox.name.split('[')[1].replace(']', '')
        const id = checkbox.value

        selectedImages[filename] = id;
        // debugger;
      }
    });

    return selectedImages;
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center bd-highlight">
        <div className="p-2">
          <h1>Add New Published Job Page</h1>
        </div>
      </div>
      <div className="d-flex justify-content-center bd-highlight">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10 rounded-block form-body">
            <form onSubmit={handleSubmit}>
              {/* Include Price Checkbox */}
              <div className="row form-group">
                <div className="col-md-12">
                  <input
                    type="checkbox"
                    id="include_price_checkbox"
                    checked={includePrice}
                    onChange={() => setIncludePrice(!includePrice)}
                  />
                  <label htmlFor="include_price_checkbox" className="form-label">Include Price</label>
                </div>
              </div>

              {/* Notes */}
              <div className="row form-group">
                <div className="col-md-12">
                  <label htmlFor="notes" className="form-label">Notes: the following notes will be used to generate this post showcasing your work.</label>
                  <textarea
                    id="notes"
                    className="form-control"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </div>

              {/* Keywords */}
              <div className="row form-group">
                <div className="col-md-12">
                  <label htmlFor="keywords" className="form-label">Keywords</label>
                  <input
                    type="text"
                    id="keywords"
                    className="form-control"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                  />
                </div>
              </div>

              {/* Customer Name */}
              <div className="row form-group">
                <div className="col-md-12">
                  <label htmlFor="customer_name" className="form-label">Customer Name</label>
                  <input
                    type="text"
                    id="customer_name"
                    className="form-control"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </div>
              </div>

              {/* Address */}
              <div className="row form-group">
                <div className="col-md-12">
                  <label htmlFor="address" className="form-label">Address</label>
                  <input
                    type="text"
                    id="address"
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>

              {/* Custom Prompt Checkbox */}
              <div className="row form-group">
                <div className="col-md-12">
                  <input
                    type="checkbox"
                    id="custom_prompt_checkbox"
                    checked={customPrompt}
                    onChange={() => setCustomPrompt(!customPrompt)}
                  />
                  <label htmlFor="custom_prompt_checkbox" className="form-label">Modify Prompt</label>
                </div>
              </div>

              {/* Custom Prompts */}
              {customPrompt && (
                <div id="custom_prompts">
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label htmlFor="custom_title_prompt" className="form-label">Custom Title Prompt</label>
                      <textarea
                        id="custom_title_prompt"
                        className="form-control"
                        value={customTitlePrompt}
                        onChange={(e) => setCustomTitlePrompt(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label htmlFor="custom_html_prompt" className="form-label">Custom HTML Prompt</label>
                      <textarea
                        id="custom_html_prompt"
                        className="form-control"
                        value={customHtmlPrompt}
                        onChange={(e) => setCustomHtmlPrompt(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Submit Button */}
              <div className="row form-group">
                <div className="col-md-12">
                  {
                    isLoading ? 
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    : 
                    <button type="submit" className="btn btn-company-primary">Submit</button>
                  }
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </React.Fragment>    
  );
};

export default NewPublishableJobPage;
