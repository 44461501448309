import React, { Component, useState } from "react"
import LocationsTable from "./LocationsTable";

export default function LocationsIndex(props){
    const [locations, setLocations] = useState(props.locations);
        return(
            <LocationsTable
                locations={locations}
                setLocations={setLocations}/>
        )
}