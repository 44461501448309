import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

function BusinessImagesToAssign() {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    city: '',
    zipCode: '',
    streetAddress: '',
    jobNotes: '',
    jobTitle: '',
    price: '',
  });
  const [page, setPage] = useState(1);
  const observer = useRef();

  const galleryStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gridGap: '15px',
    padding: '15px',
  };

  const imgStyle = {
    width: '100%',
    height: 'auto',
    border: '1px solid #ccc',
    boxShadow: '2px 2px 6px 0px rgba(0,0,0,0.2)',
    borderRadius: '4px',
    objectFit: 'cover',
  };

  useEffect(() => {
    loadImages(page);
  }, [page]);

  const loadImages = (page) => {
    setIsLoading(true);
    axios
      .get(`/business_images?page=${page}`)
      .then((response) => {
        setImages((prevImages) => [...prevImages, ...response.data]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching images', error);
        setIsLoading(false);
      });
  };

  const lastImageElementRef = useCallback((node) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading]);

  const imagePressed = (index) => {
    const indexInSelectedImages = selectedImages.indexOf(index);
    if (indexInSelectedImages !== -1) {
      setSelectedImages(selectedImages.filter((_, i) => i !== indexInSelectedImages));
    } else {
      setSelectedImages([...selectedImages, index]);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedImageIds = selectedImages.map((index) => images[index].attachment_id);
    axios
      .post('/assign_images_to_new_job', {
        ...formData,
        selectedImages: selectedImageIds,
      })
      .then((response) => {
        closeModal();
        gritterSuccess("Success!", "Images assigned to new job. Moving on to publishing.");
        let invoiceId = response.data.invoice.id;
        window.location.href = `/published_job_pages/new?invoice_id=${invoiceId}`;
      })
      .catch((error) => {
        console.error('Error assigning images to new job', error);
      });
  };

  return (
    <div>
      <button onClick={openModal}>Assign Images to New Job</button>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <h2>Assign Images to New Job</h2>
        <div>
          <h3>Selected Images:</h3>
          <div style={galleryStyle}>
            {selectedImages.map((img_idx, index) => (
              <img
                key={index}
                style={imgStyle}
                src={images[img_idx].url}
                alt="Selected Business Image"
              />
            ))}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="zipCode">Zip Code:</label>
            <input
              type="text"
              id="zipCode"
              name="zipCode"
              value={formData.zipCode}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="streetAddress">Street Address:</label>
            <input
              type="text"
              id="streetAddress"
              name="streetAddress"
              value={formData.streetAddress}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="jobNotes">Job Notes:</label>
            <textarea
              id="jobNotes"
              name="jobNotes"
              value={formData.jobNotes}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div>
            <label htmlFor="jobTitle">Job Title:</label>
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="price">Price:</label>
            <input
              type="number"
              id="price"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
        </Modal>
      <div style={galleryStyle}>
        {images.map((img, index) => {
          if (images.length === index + 1) {
            return (
              <img
                ref={lastImageElementRef}
                key={index}
                onClick={() => imagePressed(index)}
                style={{
                  ...imgStyle,
                  borderColor: `${selectedImages.includes(index) ? 'red' : ''}`,
                  borderWidth: `${selectedImages.includes(index) ? '3px' : '1px'}`,
                }}
                src={img.url}
                alt="Business Image"
              />
            );
          } else {
            return (
              <img
                key={index}
                onClick={() => imagePressed(index)}
                style={{
                  ...imgStyle,
                  borderColor: `${selectedImages.includes(index) ? 'red' : ''}`,
                  borderWidth: `${selectedImages.includes(index) ? '3px' : '1px'}`,
                }}
                src={img.url}
                alt="Business Image"
              />
            );
          }
        })}
      </div>
      {isLoading && <p>Loading...</p>}
    </div>
  );
}

export default BusinessImagesToAssign;