import React, {Component} from "react";
import Select from "react-select";
import ContentEditable from "react-contenteditable";

//Mobile Line Item

class LineItem extends Component {
    constructor(props){
        super(props); //Need an update method to edit the contents of the line item.
    }

    render() {
        const {name, description, quantity, price, productId} = this.props; //How to handle editable???
        return(
            <React.Fragment>
                <tr className="line-item-mobile">
                { this.props.editable ?
                    <React.Fragment>
                        <td colSpan={2}>
                                <Select options={this.props.productOptions}
                                    onChange={this.props.onCategoryLineSelected}
                                    value={{value: productId, label: name}}
                                    />                             
                        </td>
                        <td className="li-delete-btn" style={{textAlign:"right", padding:"5px", verticalAlign: "middle"}}> 
                            <a onClick={this.props.onRemoveItem}> 
                                <i className='fas fa-trash-alt'> </i> 
                            </a> 
                        </td>
                    </React.Fragment>
                    :
                        <td colSpan={3}>
                            {name}
                        </td>                    
                    }

                </tr>
                <tr className="line-item-mobile">
                    <td colSpan={3}>
                        <ContentEditable  
                            html={description || ""}
                            data-attribute="description"
                            onKeyDown={(e) => {
                            if (e.which == 13) e.preventDefault()
                            }} //disable newlines                        
                            onBlur={this.props.onLineItemChange}
                            placeholder={this.props.editable ? "Enter Description Here.." : ""}
                            disabled={!this.props.editable}
                            className='li-desc custom-font-size'
                        />
                    </td>
                </tr>
                <tr className="line-item-mobile">
                    <td style={{fontWeight: "bold", fontSize: "150%"}}>
                    <ContentEditable
                        html={formatCurrency(price) + ""}
                        data-attribute="price"
                        onKeyDown={onlyNumbersAllowed}
                        onBlur={this.props.onLineItemChange}
                        onFocus={this.props.onLineItemFocus}
                        disabled={!this.props.editable}
                      />                        
                    </td>
                    <th>
                        Qty:
                    </th>
                    <td>
                        <ContentEditable
                            html={quantity + ""}
                            data-attribute="quantity"
                            onKeyDown={onlyNumbersAllowed}
                            onBlur={this.props.onLineItemChange}
                            onFocus={this.props.onLineItemFocus}
                            disabled={!this.props.editable}
                            className='custom-font-size'
                        />                        
                    </td>
                </tr>
                {
                    this.props.enable_total_from_multiplying_by_quantity &&
                    <tr className="line-item-mobile">
                    <td style={{fontWeight: "bold", fontSize: "150%"}}>
                            
                    </td>
                        <th> 
                            Total:
                        </th>
                        <td style={{fontWeight: "bold", fontSize: "150%"}}>
                            <ContentEditable
                                html={formatCurrency(price * quantity) + ""}
                                data-attribute="price"
                                onKeyDown={onlyNumbersAllowed}
                                onBlur={this.props.onLineItemChange}
                                onFocus={this.props.onLineItemFocus}
                                disabled={true}
                            />                        
                        </td>
                    </tr>
                }            
                <tr className="line-item-mobile">
                    <td colSpan={3} className="line-item-mobile" style={{paddingBottom: "25px"}}>
                        <hr/>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default LineItem;

