import React from "react";

const ConfirmAccessCodeStep = ({ accessCode, setAccessCode }) => {
    return (
        <div>
            <div className="d-flex justify-content-center p-2 m-25 w-100">
                <label htmlFor="register_access_code">Access Code</label>
                <input
                    id="register_access_code"
                    type="text" // Assuming the access code is text; change the type if it's numeric or something else
                    value={accessCode}
                    className="form-control"
                    onChange={e => setAccessCode(e.target.value)}
                    placeholder="Enter your access code"
                />
            </div>
        </div>
    );
}

export default ConfirmAccessCodeStep;
