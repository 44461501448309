import React, {useEffect} from "react";
import InputField from "../../shared/Forms/InputField";

//NOTE: This must match the same surveyUserOptions in the GetStarted.jsx to make sure the server gets the right info.
const userOptions = [
  "Invoicing & Payments", "Estimates & Closing", "Scheduling Customers", "Growth & Marketing", "Other"
];

// const surveyUserOptions = ["Invoicing & Payments", "Estimates & Closing", "Scheduling Customers", "Growth & Marketing", "Other"];

const AskUserHighestPrioritySurveyOnRegistration = ({
  surveyAnswer,
  setSurveyAnswer,
  freeFormSurveyAnswer,
  setFreeFormSurveyAnswer,
  setEnableNextButton
}) => {
  
  useEffect(() => {
    let nextButtonShouldBeEnabled = surveyAnswer != undefined && surveyAnswer != null && surveyAnswer >= 0;
    
    if (surveyAnswer == 4) {//3 is hardcoded to be the "other" selection.
      nextButtonShouldBeEnabled = !isBlank(freeFormSurveyAnswer) // is not blank
      console.log(!isBlank(freeFormSurveyAnswer));
    }

    console.log("Survey answer is: " + userOptions[surveyAnswer])

    setEnableNextButton(nextButtonShouldBeEnabled);

  }, [surveyAnswer, freeFormSurveyAnswer])

  return (
    <div className="d-flex row justify-content-center">
      <div>
        {/* <div className="welcome">What Is The Main Thing You Hope To Accomplish With Field Rocket?</div> */}
        <div className="subTitle">Pick one.</div>

        {userOptions.map((item, index) => (
            <div className="p-2">
                <button
                    className={`survey-answer p-3 option ${surveyAnswer === index ? "selected" : ""}`}
                    onClick={() => setSurveyAnswer(surveyAnswer === index ? null : index)}
                    key={index}
                    style={{width: "100%"}}
                >
                    <span className="label p-3">{item}</span>
                    <input
                    type="checkbox"
                    checked={surveyAnswer === index}
                    onChange={(e) => {
                        setSurveyAnswer(e.target.checked ? index : null);
                    }}
                    />
                </button>
            </div>
        ))}

        {surveyAnswer === 4 && (
            <InputField
              id="freeFormSurveyAnswer"
              type="textarea"
              label="Please Explain:"
              value={freeFormSurveyAnswer}
              setValue={setFreeFormSurveyAnswer}
              onValidate={(answer) => !isBlank(answer)}
            />
          // <textarea
          //   type="text"
          //   value={freeFormSurveyAnswer}
          //   onChange={(e) => {
          //     setFreeFormSurveyAnswer(e.target.value)
          //   }}
          //   placeholder="What would you like to accomplish?"
          //   className="textInput"
          // />
        )}
      </div>
    </div>
  );
};

export default AskUserHighestPrioritySurveyOnRegistration;