import React, {useState, useEffect} from "react"
import TableDataNotEditable from "../shared/TableDataNotEditable"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

export default function ContactsTable({contacts, setContacts}){
    const [isOpen, setIsOpen] = useState(false);
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
    const [activeContactId, setActiveContactId] = useState(0);
    
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [notes, setNotes] = useState(null);
    const [modalTitle, setModalTitle] = useState("");

    const editButtonClicked = (contactId, contactData) => {
        console.log("Edit button clicked for contact! About to hit debugger");
        console.log("Contact Id: " + contactId);
        console.log("Contact data: ");
        console.log(contactData);
        setActiveContactId(contactId);
        
        const selectedContact = contacts.find(contact => contact.id == contactId);
        console.log("Selected contact: ", selectedContact);
        setFirstName(selectedContact.first_name);
        setLastName(selectedContact.last_name);
        setPhone(selectedContact.phone);
        setEmail(selectedContact.email);
        setNotes(selectedContact.notes);
        setModalTitle("Edit Contact");
        setIsOpen(true);
    }; //after a timesheet gets edited.. we need to modify the state somehow.

    const saveContactChangesToServer = () => {//This is a closure... I don't think it will work like this (I don't think this closure gets updated)
        setIsSaveButtonLoading(true);
        $.ajax({
            type: 'PUT',
            url: `/customer_contacts/${activeContactId}.json`,
            data: {customer_contact: {id: activeContactId, first_name: firstName, last_name: lastName, phone: phone, email: email, notes: notes}},
            success: (data) => {
                console.log("Success: " + data);
                setIsOpen(false);
                gritterSuccess("Success", "Successfully Updated Contact", 10000);
                const contactsWithEdits = [...contacts];
                for (let index = 0; index < contacts.length; index++){
                    const contact = contacts[index];
                    const modifiedThisContact = contact["id"] == data.id;
                    if (modifiedThisContact){
                        contactsWithEdits[index] = data;
                    }
                }
                setContacts(contactsWithEdits);
                setIsSaveButtonLoading(false);
            },
            error: (data) => {
                console.log("error: " + data);
                gritterError("Warning", "Couldn't Update Contact", 10000);
                setIsSaveButtonLoading(false);
            },
        })
    }

    return(
        <React.Fragment>
            <TableDataNotEditable
                basePath="/customer_contacts"
                title={'Customer Contacts'}
                attributes={["Name", "Phone", "Email", "Notes"]}
                data={contacts.map((contact) => [
                        contact.id, 
                        contact.full_name, 
                        formatPhoneNumber(contact.phone),
                        contact.email,
                        contact.notes])}
                actions={{delete: true, edit: true}}
                editModalOnClick={editButtonClicked}
            />
            
            <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
                <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        {/* TODO: customer contact fields/form. */}
                        <label for="contact_first_name" className="text-label invoice_type_due_label">First Name:</label>
                        <input id="contact_first_name" type="text" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} />

                        <label for="contact_last_name" className="text-label invoice_type_due_label">Last Name:</label>
                        <input id="contact_last_name" type="text" className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)} />

                        <label for="contact_phone" className="text-label invoice_type_due_label">Phone:</label>
                        <input id="contact_phone" type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />

                        <label for="contact_email" className="text-label invoice_type_due_label">Email:</label>
                        <input id="contact_email" type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                        
                        <label for="contact_notes" className="text-label invoice_type_due_label">Notes:</label>
                        <input id="contact_notes" type="text" className="form-control" value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
                {isSaveButtonLoading ? 
                    <Button variant="primary" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </Button>
                        :
                    <Button variant="primary" onClick={saveContactChangesToServer}>
                        Save Changes
                    </Button>
                }
                </Modal.Footer>
            </Modal>
        </React.Fragment>
        )
}