import React from "react";
import AppleIcon from 'images/social/apple-Icon-metro.svg'; // Adjust the path as per your directory structure
import AndroidIcon from 'images/social/android-Icon-material.svg'; // Adjust the path as per your directory structure

const DoneStep = ({email}) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center p-3">
            <h3> <a href={`/employees/password/new?email=${email}`} style={{padding: 5, margin: 5}}> Set a password and sign in</a> </h3>
            <p style={{padding: 5, margin: 5}}> After setting your password, make sure to click "Already have an account?"</p>
            {/* <button className="btn btn-primary mt-3">Download Mobile App</button> */}
            <div className="d-flex justify-content-center align-items-center" style={{height:"50px", margin: "15px"}}>
                <button className="mobile-download-button"
                    style={{borderRadius: "12px", backgroundColor: "#ede8e8", padding: "15px", margin: "15px", border: "none"}}
                    onClick={() => window.location='https://apps.apple.com/us/app/field-rocket/id1602587569'}
                    > 
                    <img style={{width: 31, height: 31, margin: 5}} src={AppleIcon} alt="" />
                    Download iPhone </button>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{height:"50px", margin: "15px"}}>
                <button className="mobile-download-button"
                    style={{borderRadius: "12px", backgroundColor: "#ede8e8", padding: "15px", margin: "15px", border: "none"}}
                    onClick={() => window.location='https://play.google.com/store/apps/details?id=com.getservicerocket.mobile&hl=en_US&gl=US'}
                > 
                <img style={{width: 31, height: 31, margin: 5}} src={AndroidIcon} alt="" />
                Download Android </button>
            </div>
        </div>
    );
}

export default DoneStep;