import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ChatBot = ({ first_name, context }) => {
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  console.log("ChatBot props:", { first_name, context });


  useEffect(() => {
    // Add initial greeting message when component mounts
    // console.log("app context is:" + context);
    setMessages([
      { text: `What do you want to build?`, sender: 'bot' }
    ]);
  }, [first_name]);

  useEffect(() => {
    // Add initial greeting message when component mounts
    console.log("app context is:", context);
    setMessages([
      { text: `What do you want to build?`, sender: 'bot' }
    ]);
  }, [context]);

  const styles = {
    chatBubbleContainer: {
      position: 'fixed',
      bottom: '16px',
      right: '16px',
      zIndex: 50,
    },
    chatIcon: {
      width: '64px',
      height: '64px',
      backgroundColor: '#3B82F6', // blue-500
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    chatIconImage: {
      width: '48px',
      height: '48px',
    },
    messageBubble: {
      position: 'absolute',
      bottom: '80px',
      right: '0',
      backgroundColor: '#1F2937', // gray-800
      borderRadius: '8px',
      padding: '12px',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    },
    messageBubbleText: {
      color: 'white',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
    messageBubbleTail: {
      position: 'absolute',
      bottom: '-6px',
      right: '24px',
      width: '12px',
      height: '12px',
      backgroundColor: '#1F2937', // gray-800
      transform: 'rotate(45deg)',
    },
    chatbotContainer: {
      position: 'fixed',
      bottom: '90px',
      right: '20px',
      width: '300px',
      height: '400px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
      zIndex: 1000,
      display: isOpen ? 'flex' : 'none',
      flexDirection: 'column',
      backgroundColor: '#fff',
    },
    chatbotHeader: {
      padding: '10px',
      backgroundColor: '#007bff',
      color: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      color: '#fff',
      fontSize: '20px',
      cursor: 'pointer',
    },
    chatbotMessages: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: '10px',
    },
    message: {
      margin: '5px 0',
      padding: '8px',
      borderRadius: '8px',
    },
    botMessage: {
      backgroundColor: '#e6f2ff',
      alignSelf: 'flex-start',
    },
    userMessage: {
      backgroundColor: '#dcf8c6',
      alignSelf: 'flex-end',
    },
    inputContainer: {
      display: 'flex',
      padding: '10px',
    },
    input: {
      flexGrow: 1,
      marginRight: '10px',
      padding: '5px',
    },
    sendButton: {
      padding: '5px 10px',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  };

  const loadingIndicatorStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSendMessage = async () => {
    if (userInput.trim() === '') return;

    const newUserMessage = { text: userInput, sender: 'user' };
    setMessages([...messages, newUserMessage]);
    setUserInput('');
    setIsLoading(true);

    try {
      const response = await axios.post('/chatbot/message', { message: userInput, context: context });
      const botResponse = { text: response.data.response, sender: 'bot' };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
    } catch (error) {
      console.error('Error sending message to ChatGPT:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div style={styles.chatBubbleContainer}>
        <div style={styles.chatIcon} onClick={toggleChat}>
          <img
            src="https://service-jobs-images.s3.us-east-2.amazonaws.com/7rl98t1weu387r43il97h6ipk1l7"
            alt="LlamaPress Logo"
            style={styles.chatIconImage}
          />
        </div>
        <div style={styles.messageBubble}>
          <p style={styles.messageBubbleText}>What do you want to build?</p>
          <div style={styles.messageBubbleTail}></div>
        </div>
      </div>
      <div style={styles.chatbotContainer}>
        <div style={styles.chatbotHeader}>
          <span>What do you want to build? </span>
          <button style={styles.closeButton} onClick={toggleChat}>×</button>
        </div>
        <div style={styles.chatbotMessages}>
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                ...styles.message,
                ...(message.sender === 'bot' ? styles.botMessage : styles.userMessage),
              }}
            >
              {message.text}
            </div>
          ))}
          {isLoading && (
            <div style={loadingIndicatorStyle}>
              <span>Thinking...</span>
            </div>
          )}
        </div>
        <div style={styles.inputContainer}>
          <input
            type="text"
            value={userInput}
            onChange={handleInputChange}
            style={styles.input}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <button onClick={handleSendMessage} style={styles.sendButton} disabled={isLoading}>
            Send
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatBot;