import React, {useState, useEffect} from "react"
import TableDataNotEditable from "../shared/TableDataNotEditable"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

export default function LocationsTable({locations, setLocations}){
    const [isOpen, setIsOpen] = useState(false);
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
    const [activeLocationId, setActiveLocationId] = useState(0);
    
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState(null);
    const [state, setState] = useState(null);
    const [modalTitle, setModalTitle] = useState("");

    const editButtonClicked = (locationId, locationData) => {
        console.log("Edit button clicked for location! About to hit debugger");
        console.log("Location Id: " + locationId);
        console.log("Location data: ");
        console.log(locationData);
        setActiveLocationId(locationId);
        
        const selectedLocation = locations.find(location => location.id == locationId);
        console.log("Selected Location: ", selectedLocation);
        setStreetAddress(selectedLocation.street_address);
        setCity(selectedLocation.city);
        setZip(selectedLocation.zip_code);
        setState(selectedLocation.state);
        setModalTitle("Edit Location");
        setIsOpen(true);
    }; //after a timesheet gets edited.. we need to modify the state somehow.

    const saveLocationChangesToServer = () => {//This is a closure... I don't think it will work like this (I don't think this closure gets updated)
        setIsSaveButtonLoading(true);
        $.ajax({
            type: 'PUT',
            url: `/locations/${activeLocationId}.json`,
            data: {location: {id: activeLocationId, street_address: streetAddress, city: city, zip_code: zip, state: state}},
            success: (data) => {
                console.log("Success: " + data);
                setIsOpen(false);
                gritterSuccess("Success", "Successfully Updated Location", 10000);
                const locationsWithEdits = [...locations];
                for (let index = 0; index < locations.length; index++){
                    const location = locations[index];
                    const modifiedThislocation = location["id"] == data.id;
                    if (modifiedThislocation){
                        locationsWithEdits[index] = data;
                    }
                }
                setLocations(locationsWithEdits);
                setIsSaveButtonLoading(false);
            },
            error: (data) => {
                console.log("error: " + data);
                gritterError("Warning", "Couldn't Update location", 10000);
                setIsSaveButtonLoading(false);
            },
        })
    }

    return(
        <React.Fragment>
            <TableDataNotEditable
                basePath="/locations"
                title={'Customer Locations'}
                attributes={["Street Address", "City", "Zip", "Is Billing"]}
                data={locations.map((location) => [
                        location.id, 
                        location.street_address,
                        location.city, 
                        location.zip_code,
                        location.is_billing_address == true ? "Is Billing" : {url: `/locations/${location.id}/make_default`, icon: 'fa-map-marked'}])}
                actions={{delete: true, edit: true}}
                editModalOnClick={editButtonClicked}
            />
            
            <Modal show={isOpen} onHide={() => setIsOpen(false)} centered>
                <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        {/* TODO: customer location fields/form. */}
                        <label for="location_street_address" className="text-label invoice_type_due_label">Street Address:</label>
                        <input type="text" id="location_street_address" className="form-control" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />

                        <label for="location_city" className="text-label invoice_type_due_label">City:</label>
                        <input id="location_city" type="text" className="form-control" value={city} onChange={(e) => setCity(e.target.value)} />

                        <label for="location_zip" className="text-label invoice_type_due_label">Zip:</label>
                        <input id="location_zip" type="text" className="form-control" value={zip} onChange={(e) => setZip(e.target.value)} />

                        <label for="location_state" className="text-label invoice_type_due_label">state:</label>
                        <input id="location_state" type="text" className="form-control" value={state} onChange={(e) => setState(e.target.value)} />
                        
                    </form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsOpen(false)}>
                    Close
                </Button>
                {isSaveButtonLoading ? 
                    <Button variant="primary" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Saving...
                    </Button>
                        :
                    <Button variant="primary" onClick={saveLocationChangesToServer}>
                        Save Changes
                    </Button>
                }
                </Modal.Footer>
            </Modal>
        </React.Fragment>
        )
}