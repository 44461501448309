import React, {useState, useEffect} from "react";

const ExpandedBodyRow = ({publishableJob, index, isExpanded }) => {
    const [images, setImages] = useState([])
    useEffect(() => {
        // GET /customers/1/images.json
        const fetchImages = async () => { 
          if (isExpanded) {//lazy load only when expanded
            try {
              const response = await fetch(`/customers/${publishableJob.customer_id}/images.json`);
              if (response.ok) {
                const data = await response.json();
                // debugger;
                setImages(data["images"]);
              } else {
                console.error('Failed to fetch images:', response.status);
              }
            } catch (error) {
              console.error('Error fetching images:', error);
            }
          }
        };
    
        fetchImages();
      }, [isExpanded, publishableJob.customer_id]);

return (
    <tr
            className={`${isExpanded ? '' : 'hidden'} flex-1 overflow-x-auto w-full`}
            id={`table-column-body-${index}`}
            aria-labelledby={`table-column-header-${index}`}
        >
            <td className="p-4 border-b dark:border-gray-700" colSpan="9">
            <div className="grid grid-cols-4 gap-4 mb-4">
                {images.map((image, idx) => (
                    <div className="relative p-2 bg-gray-100 rounded-lg sm:w-full h-32 sm:h-36 dark:bg-gray-700 flex items-center justify-center" key={idx}>
                        <img
                            src={image.url} 
                            alt={`Image ${idx + 1}`}
                            className="h-full w-auto"
                        />
                    </div>
                ))}
                {/* <div className="relative p-2 bg-gray-100 rounded-lg sm:w-full h-32 sm:h-36 dark:bg-gray-700 flex items-center justify-center">
                <img
                    src="https://flowbite.s3.amazonaws.com/blocks/application-ui/products/imac-front-image.png"
                    alt="iMac Front Image"
                    className="h-full w-auto"
                />
                </div>*/}
            </div>
            <div>
                {
                    publishableJob.published_job_page?.slug != null 
                    ? 
                        (
                            <div>
                                <div className="w-fit bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
                                    Published
                                </div>
                                <a href={`${publishableJob.published_job_page?.slug}`}> View on their Website: </a>
                            </div>
                        )
                    : 
                        (
                            <div className="w-fit bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-100 dark:text-red-800">
                                Not Published
                            </div>
                        )
                }
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                Details
                </h6>
                <div className="text-base text-gray-500 dark:text-gray-400 max-w-screen-md">
                {publishableJob.notes} <br/>
                {publishableJob.line_items.map((lineItem, idx) => (
                    <p key={idx}> {lineItem.description} - ${lineItem.price} </p>
                ))}
                </div>
            </div>
            <div className="grid grid-cols-4 gap-4 mt-4">
                <div className="relative p-3 bg-gray-100 rounded-lg dark:bg-gray-700 flex flex-col items-start justify-between">
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                    Address
                </h6>
                <div className="bg-primary-100 text-primary-800 text-xs font-medium px-2.5 py-0.5 rounded-md dark:bg-primary-200 dark:text-primary-800 flex items-center">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                    {publishableJob.display_address}
                </div>
                </div>
                <div className="relative p-3 bg-gray-100 rounded-lg dark:bg-gray-700 flex flex-col justify-between">
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                    Total Amount
                </h6>
                <div className="flex items-center text-gray-500 dark:text-gray-400">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    >
                    <path
                        fillRule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                    />
                    </svg>
                    ${publishableJob.total_price}
                </div>
                </div>
                <div className="relative p-3 bg-gray-100 rounded-lg dark:bg-gray-700">
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                    Customer Name
                </h6>
                <div className="flex items-center space-x-2">
                    <a href={`/customers/${publishableJob.customer_id}`}> {publishableJob.customer_name} </a>
                </div>
                </div>
                <div className="relative p-3 bg-gray-100 rounded-lg dark:bg-gray-700">
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                    Brand
                </h6>
                <div className="flex items-center text-gray-500 dark:text-gray-400">Apple</div>
                </div>
                <div className="relative p-3 bg-gray-100 rounded-lg dark:bg-gray-700">
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                    Sold by
                </h6>
                <div className="flex items-center text-gray-500 dark:text-gray-400">Flowbite</div>
                </div>
                <div className="relative p-3 bg-gray-100 rounded-lg dark:bg-gray-700">
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                    Ships from
                </h6>
                <div className="flex items-center text-gray-500 dark:text-gray-400">Flowbite</div>
                </div>
                <div className="relative p-3 bg-gray-100 rounded-lg dark:bg-gray-700">
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                    Dimensions (cm)
                </h6>
                <div className="flex items-center text-gray-500 dark:text-gray-400">105 x 15 x 23</div>
                </div>
                <div className="relative p-3 bg-gray-100 rounded-lg dark:bg-gray-700">
                <h6 className="mb-2 text-base leading-none font-medium text-gray-900 dark:text-white">
                    Item weight
                </h6>
                <div className="flex items-center text-gray-500 dark:text-gray-400">12kg</div>
                </div>
            </div>
            <div className="flex items-center space-x-3 mt-4">
                <button
                type="button"
                className="py-2 px-3 flex items-center text-sm font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                    clipRule="evenodd"
                    />
                </svg>
                Edit
                </button>
                <button
                type="button"
                className="py-2 px-3 flex items-center text-sm font-medium text-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={() => {window.location.href = `/published_job_pages/new?invoice_id=${publishableJob.id}`}}
                >
                Publish
                </button>
                <button
                    type="button"
                    className="py-2 px-3 flex items-center text-sm font-medium text-center text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 rounded-lg dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                    >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 mr-1"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                        fillRule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                        />
                    </svg>
                    Delete
                    </button>
            </div>
            </td>
        </tr>
)}

export default ExpandedBodyRow;