import React, { Component, useState } from "react"
import ContactsTable from "./ContactsTable";

export default function ContactsIndex(props){
    const [contacts, setContacts] = useState(props.contacts);
        return(
            <ContactsTable
                contacts={contacts}
                setContacts={setContacts}/>
        )
}