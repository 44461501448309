import React, {Component} from "react";

//Desktop Line Item Header

class LineItemHeader extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return(
            <React.Fragment>
                <tr className="heading line-item-desktop">
                    <td>
                    Category 
                    </td>
                    <td>
                    Description
                    </td>
                    <td style={{display: 'none'}}>
                    Product ID
                    </td>
                    <td style={{textAlign: 'right'}}>
                    Quantity
                    </td>
                    <td className="price" style={{textAlign: 'right'}}>
                    Price
                    </td>
                    {this.props.enable_total_from_multiplying_by_quantity && (
                        <td className="price" style={{textAlign: 'right'}}>Amount</td>
                    )}
                    {
                        this.props.editable &&
                        <td style={{textAlign: 'right', paddingLeft: "10px"}}>
                            <a id="add-line-item" onClick={this.props.addLineItem}> <i className="fas fa-plus fa-lg"></i> </a>
                        </td>
                    }
                </tr>
                <tr id="line-item-anchor-row" />
          </React.Fragment>          
        );
    }
}

export default LineItemHeader;
