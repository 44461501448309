import React, { Component } from "react";

class TableDataNotEditable extends Component{
    constructor(props){
        super(props)
    }

    render(){
        const {title, attributes, data, basePath, actions, editModalOnClick } = this.props;
        const responsiveStyleWrapper = {
            overflowX: "auto"
        }

        var showActions = actions;
        if (showActions == null){ //These are the default actions
            showActions = {delete: true, edit: true, view: true}
        }

        const tableHeaders = this._buildTopRow(attributes);
        const tableData = this._buildTableBodyRows(data, basePath, showActions, editModalOnClick);   
        return(
            <div style={responsiveStyleWrapper}>
                <table className="table mb-0 thead-border-top-0" id={title}>
                    <thead>
                        <tr>
                        {tableHeaders}
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="list" id="staff02">
                        {tableData}
                    </tbody>
                </table>
            </div>
        );
    };

    _buildTopRow(attributes){
        const tableHeaders = []
        for (const [index, value] of attributes.entries()){
            const titleizedValue = value.replace(/^\w/, c => c.toUpperCase());
            tableHeaders.push(<th key={index}>{titleizedValue}</th>);
        };
        return tableHeaders;
    }

    _buildTableBodyRows(data, basePath, actions, editModalOnClick){
        const tableData = [];
        for (const [index, value] of data.entries()){
            const row = [];
            const productId = value[0]; //First element is always the id
            for (const [objectIndex, objectValue] of value.entries()){
                const lookingAtObjectsId = objectIndex == 0;
                const lookingAtUrlObject = objectValue != null && objectValue["url"] != null;
                if (lookingAtObjectsId){ //We don't want to add a <td> just for the ID.              
                    continue;
                }
                else if (lookingAtUrlObject){
                    const openInNewTab = objectValue["newtab"] != null;
                    const icon = objectValue["icon"] == null ? null : (<i className={`fas ${objectValue["icon"]}`}> {objectValue["title"]} </i>);
                    const iconFullClass = objectValue["iconclass"] == null ? null : (<i className={`${objectValue["iconclass"]}`}> {objectValue["title"]} </i>); // This extra one is because some icons don't start with 'fas', they might start with 'fab', like in the case with the stripe icon. 
                    const displayText = icon == null && iconFullClass == null ? objectValue["title"] : icon || iconFullClass
                    const linkClass = objectValue["class"] != null ? objectValue["class"] : "";
                    const hover = objectValue["hover"] != null ? objectValue["hover"] : "";
                    const target = openInNewTab ? "_blank" : "";
                    row.push(<td key={`${objectIndex}-a`}><a href={objectValue["url"]} className={linkClass} title={hover} data-custom={objectValue["dataCustom"]} target={target}> {displayText} </a></td>)
                }
                else {
                    row.push(<td key={`${objectIndex}-b`}>{objectValue}</td>)
                }
            }
            
            row.push(
                this.actionsTd(productId, basePath, actions, editModalOnClick, data)
            );
            tableData.push(<tr key={index}>{row}</tr>)
        };
        return tableData;
    }

    /**
     * Builds the Actions Table Data Column with the view, edit, and delete icons.  
     * @param {*} productId The id of the resource that is being shown 
     * @param {*} basePath The path of the resource used for viewing, editing, and POSTing a delete. (for the default actions of viewing, editing, or deleting)
     * @param {*} actions The actions that are enabled for this resource row (sometimes you don't want to show viewing, or editing, or delete, sometimes you want to show all, sometimes you want to show none.)
     * @param {*} editModalOnClick This is a callback used to show a modal if we are going to allow editing this resource directly on this same page, using a modal. 
     * @param {*} data Pass in the data for this resource so we can set the data in the modal fields so the user can see it before making a change.
     * @returns 
     */
    actionsTd(productId, basePath, actions, editModalOnClick, data) {
        var viewElement = null;
        if (actions.view){
            viewElement = 
            (<div className="p-2">
                <a href={`${basePath}/${productId}`} title="View"><i className="fas fa-eye 2x"></i></a>
            </div>);  
        }
        
        var editElement = null;
        if (actions.edit){
            if (editModalOnClick != null){
                editElement = 
                (<div className="p-2">
                    <a href='#' onClick={() => editModalOnClick(productId, data)} title="Edit" className={`edit_${basePath}`}><i className="fas fa-pencil-alt 2x"></i></a>
                </div>);  
            }
            else {
                editElement = 
                (<div className="p-2">
                    <a href={`${basePath}/${productId}/edit`} title="Edit"><i className="fas fa-pencil-alt 2x"></i></a>
                </div>);  
            }
            
        }

        var deleteElement = null;
        if (actions.delete){
            deleteElement = 
            (<div className="p-2">
                <a href={`${basePath}/${productId}`} data-method="DELETE" data-confirm="Are you sure?" title="Delete"><i className="fas fa-trash 2x"></i></a>
            </div>);
        }

        return <td key={`${productId}-c`}>
            <div className="d-flex justify-content-start">
                {viewElement}
                {editElement}
                {deleteElement}
            </div>
        </td>;
    }
}

export default TableDataNotEditable;