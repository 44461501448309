import React, {Component} from "react";

class TotalDue extends Component {
    constructor(props){
        super(props);
    }

    render() {
        const { totalAmount, totalAmountPaid, amountDue, subtotalAmount, salesTaxAmount, enableSalesTaxCalculation, salesTax } = this.props;
        return(
            //TODO: Mike - if (invoice.subTotal > 0 && invoice.sales_tax > 0)
            <React.Fragment>
                <div className="total">
                <br />
                    {enableSalesTaxCalculation && subtotalAmount > 0 && salesTaxAmount > 0 &&
                        <>
                            <h4>
                                <b>
                                    <span style={{ color: 'darkred' }} id="invoice-amount-due-label">Subtotal: </span>
                                    <span id="line-item-total">
                                        {formatCurrency(subtotalAmount)}
                                    </span>
                                </b>
                            </h4>
                            <h4>
                                <b>
                                    <span style={{ color: 'darkred' }} id="invoice-amount-due-label">HST @ {salesTax*100}%: </span>
                                    <span id="line-item-total">
                                        {formatCurrency(salesTaxAmount)}
                                    </span>
                                </b>
                            </h4>
                        </>
                    }
                    <h2> 
                        <b>
                            <span style={{color: 'darkred'}} id="invoice-amount-due-label">Due: </span>
                            <span id="line-item-total">
                            { formatCurrency(amountDue) }
                            </span>
                        </b>
                    </h2>
                </div>
                <table>
                    <tbody>
                        <tr>
                        <td style={{paddingTop: '20px'}}><b> Paid: </b><span style={{color: 'green'}}> {formatCurrency(totalAmountPaid)}</span></td>
                        <td style={{paddingTop: '20px'}}>
                        <b> Original Total: </b> { formatCurrency(totalAmount) }
                        </td>
                    </tr>
                    </tbody>
                </table>    
            </React.Fragment>
        );
    }
}

export default TotalDue;

