import React, { Component } from "react";
import SortLink from "../shared/SortLink";

class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <thead>
                <tr>
                    <th> Avatar </th>
                    <th><SortLink name="First Name" url="/employees" /></th>
                    <th><SortLink name="Last Name" url="/employees" /></th>
                    <th>Email</th>
                    <th>Phone </th>
                    <th>Color</th>
                    <th>Group</th>
                    <th></th>
                </tr>
            </thead>
        );
    }
}

export default TableHeader;
