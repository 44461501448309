import React, {useState} from "react";
import InputField from "./InputField";

const EmailInputField = ({id, email, setEmail}) => {
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [invalidMessage, setInvalidMessage] = useState("");

    const validateEmailInput = (userInputtedEmail) => {

        if (isValidEmail(userInputtedEmail)){
            console.log(`Email ${userInputtedEmail} is valid!`)
            setEmailIsValid(true);
            return true;
        } 
        else {
            console.log(`Email ${userInputtedEmail} is NOT valid!`)
            setEmailIsValid(false);
            setInvalidMessage("Email: Please enter a valid email address.");
            return false;
        }
    }    

    return(
        <InputField
            id={id}
            type="email"
            label="Email"
            value={email}
            setValue={setEmail}
            isValid={emailIsValid}
            setIsValid={setEmailIsValid}
            invalidMessage={invalidMessage}
            onValidate={validateEmailInput}
        />
    )
}

export default EmailInputField;