import React, {useState} from "react";

const InputField = ({id, label, value, setValue, isValid=true, onValidate=null, type="text", invalidMessage=""}) => {

    const baseClass = `form-control ${type=="tel" ? "phone_mask" : ''}`
    const [inputClassName, setInputClassName] = useState(baseClass);

    const addValidCSSClassOnBlur = (isValidated) => {
        if (isValidated){
            setInputClassName(baseClass + " is-valid");
        }
        else {
            setInputClassName(baseClass + " is-invalid");
        }
    }

    return(
        <div className="d-flex justify-content-center p-2 m-25 w-100">
            <div className="d-flex row" style={{width: "75%"}}>
                <label style={{color: isValid ? "#333333" : "#ea3223"}} htmlFor="register_first_name">{isValid ? label : invalidMessage}</label>
                { type == "textarea" ? 
                <textarea 
                    id={id}
                    type={type}
                    value={value}
                    className={inputClassName}
                    onChange={e => setValue(e.target.value)}
                    onBlur={(element) => {
                        console.log("Made it to on blur in InputField")
                        const isValidated = onValidate(element.target.value);
                        addValidCSSClassOnBlur(isValidated);
                    }}
                /> : 
                <input
                    id={id}
                    type={type}
                    value={value}
                    className={inputClassName}
                    onChange={e => setValue(e.target.value)}
                    onBlur={(element) => {
                        console.log("Made it to on blur in InputField")
                        const isValidated = onValidate(element.target.value);
                        addValidCSSClassOnBlur(isValidated);
                    }}
                />
                }
            </div>
        </div>
    )
}

export default InputField;