import React, {Component} from "react";
import LogoPath from "images/fieldrocketlogo.png"

const FieldRocketLogo = () => {
    return(
        <div className="d-flex flex-column justify-content-center align-items-center mt-2 navbar-light">
        <a href="/" className="navbar-brand flex-column mb-2 align-items-center mr-0" style={{minWidth: 0}}>
          <img className="navbar-brand-icon mr-0 mb-2" src={LogoPath} width="150" alt="Stack"></img>
          {/* <span>Field Rocket</span> */}
        </a>
        {/* <p class="m-0">Login to Field Rocket</p> */}
      </div>
    )
}

export default FieldRocketLogo;