import React, { useState, useEffect } from 'react';

const SEOForm = ({ invoice, images }) => {
    const [notes, setNotes] = useState(invoice?.notes || '');
    const [keywords, setKeywords] = useState('');
    const [customerName, setCustomerName] = useState(invoice?.customer?.first_name || '');
    const [address, setAddress] = useState(`${invoice?.customer?.location?.city}, ${invoice?.customer?.location?.state}` || '');
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        debugger;
    }, [
        
    ])

    const handleImageCheck = (image, isChecked) => {
        if (isChecked) {
            setSelectedImages([...selectedImages, image]);
        } else {
            setSelectedImages(selectedImages.filter(img => img !== image));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can call the method to post data to your Rails backend
        console.log({ notes, keywords, customerName, address, selectedImages });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Notes:</label>
                <textarea value={notes} onChange={e => setNotes(e.target.value)} />
            </div>
            <div>
                <label>Keywords:</label>
                <input type="text" value={keywords} onChange={e => setKeywords(e.target.value)} />
            </div>
            <div>
                <label>Customer Name:</label>
                <input type="text" value={customerName} onChange={e => setCustomerName(e.target.value)} />
            </div>
            <div>
                <label>Address:</label>
                <input type="text" value={address} onChange={e => setAddress(e.target.value)} />
            </div>
            <div>
                <label>Images:</label>
                {images.map((image, index) => (
                    <div key={index}>
                        <input
                            type="checkbox"
                            checked={selectedImages.includes(image)}
                            onChange={e => handleImageCheck(image, e.target.checked)}
                        />
                        <img src={image} alt={`Customer Image ${index}`} />
                    </div>
                ))}
            </div>
            <button type="submit">Submit</button>
        </form>
    );
};

export default SEOForm;