import React, {useEffect} from "react";
import InputField from "../../shared/Forms/InputField";

const HowDidYouHearAboutUs = ({
  howDidYouHearAboutUsAnswer,
  setHowDidYouHearaboutUsAnswer,
  setEnableNextButton
}) => {
  
  useEffect(() => {
    let nextButtonShouldBeEnabled = howDidYouHearAboutUsAnswer != undefined && howDidYouHearAboutUsAnswer != null && !isBlank(howDidYouHearAboutUsAnswer);
    setEnableNextButton(nextButtonShouldBeEnabled);
  }, [howDidYouHearAboutUsAnswer])

  return (
    <div className="d-flex row justify-content-center">
      <div>
        {/* <div className="subTitle">How did you hear about Field Rocket?</div> */}
        <InputField
              id="freeFormHowDidYouHearAboutUs"
              type="textarea"
              label="Please Explain:"
              value={howDidYouHearAboutUsAnswer}
              setValue={setHowDidYouHearaboutUsAnswer}
              onValidate={(answer) => !isBlank(answer)}
            />
      </div>
    </div>
  );
};

export default HowDidYouHearAboutUs;