import React, {useEffect, useState} from "react";
import InputField from "../../shared/Forms/InputField";

const PhoneNumberStep = ({ phone, setPhone, setEnableNextButton }) => {
    const [isValid, setIsValid] = useState(true);
    const [invalidMessage, setInvalidMessage] = useState("");

    /**
     * This is a super annoying hack, because phone-mask.js for some reason isn't running on this page after the 
     * className is set properly in InputField. So I'm just re-running the code needed to add the formatting for the
     * phone number here.
     */
    useEffect(() => {
        $('.phone_mask').mask('(000) 000-0000');
        $('.phone_mask').bind('paste', (event) => { //I added this in so that you could copy and paste from iPhone phone numbers into the app.. the +1 was throwing off our phone_masking. 
            var pastedData = event.originalEvent.clipboardData.getData('text')
            if (pastedData[0] == "+" && pastedData[1] == "1"){
                let filteredPhoneNumber = pastedData.substring(2); //trim out the +1 from phone number if it exists..
                console.log(`Modified Pasted phone number from: ${pastedData} to: ${filteredPhoneNumber}!`);
                // event.originalEvent.clipboardData.setData('text/plain', filteredPhoneNumber);
                event.target.value = filteredPhoneNumber
            }
        });
    })

    useEffect(() => {
        let formattedPhoneNumber = formatPhoneNumber(phone);
        let isValid = isValidPhoneNumber(formattedPhoneNumber);
        setEnableNextButton(isValid);
    }, [phone])

    const onValidatePhoneNumber = (phoneNumber) => {
        let formattedPhoneNumber = formatPhoneNumber(phoneNumber);
        let isValid = isValidPhoneNumber(formattedPhoneNumber);
        if (isValid) {
            setInvalidMessage("");
            setIsValid(true);
            return true;
        }
        else {
            setInvalidMessage("Please enter a full phone number")
            setIsValid(false);
            return false;
        }
    }

    return (
        <div>
            <InputField
                id="register_phone_number"
                type="tel"
                value={phone}
                label="Phone"
                invalidMessage={invalidMessage}
                isValid={isValid}
                setValue={setPhone}
                onValidate={onValidatePhoneNumber}/>
        </div>
    );
}

export default PhoneNumberStep;
