import React, {useEffect} from "react";

const ExpandableTableRow = ({publishableJob, index, toggleAccordion }) => {

    useEffect(() => {
        console.log("publishable Job: ", publishableJob)
    }, [])

    return(
        <tr
        className="border-b dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer transition"
        id={`table-column-header-${index}`}
        data-accordion-target={`#table-column-body-${index}`}
        aria-expanded="false"
        aria-controls={`table-column-body-${index}`}
        onClick={toggleAccordion}
        >
            
        <td className="px-4 py-3 w-4">
        <div className="flex items-center">
            <input
            id="checkbox-table-search-1"
            type="checkbox"
            onClick={(event) => event.stopPropagation()}
            className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-table-search-1" className="sr-only">
            checkbox
            </label>
        </div>
        </td>
        <td className="p-3 w-4">
        <svg
            data-accordion-icon=""
            className="w-6 h-6 shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
            />
        </svg>
        </td>
        <th
        scope="row"
        className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white flex items-center"
        >
        {/* <i class="fa-brands fa-wordpress"></i> */}
        <img
            src="https://flowbite.s3.amazonaws.com/blocks/application-ui/products/imac-front-image.png"
            alt="iMac Front Image"
            className="h-8 w-auto mr-3"
        />
        {publishableJob.customer_name}
        </th>
        <td className="px-4 py-3">
        {moment(publishableJob.created_at).format('YYYY-MM-DD')}
        </td>
        <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {publishableJob.job_address?.city + ', ' + publishableJob.job_address?.state}
        </td>
        <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
        ${publishableJob.total_price}
        </td>
        <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {/* { false ? ( */}
            
            {publishableJob.published_job_page?.id != null && publishableJob.published_job_page?.slug != null ? (
                <div className="w-fit bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
                    Published
                </div>
                ) : (
                publishableJob.published_job_page?.id != null ? (
                    <div className="w-fit bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-100 dark:text-yellow-800">
                        Created
                    </div>
                ) : (
                    <div className="w-fit bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-100 dark:text-red-800">
                    Not Published
                    </div>
                )
            )}
        
        </td>
        <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {publishableJob.published_job_page?.number_of_views || 0}
        </td>
        <td className="px-4 py-3 whitespace-nowrap">
        <div className="w-fit bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-100 dark:text-green-800">
            $0
        </div>
        </td>
        </tr>)
}

export default ExpandableTableRow;