import React, {useState, useEffect} from "react";
import InputField from "../../shared/Forms/InputField";
import EmailInputField from "../../shared/Forms/EmailInputField";

const WhatsYourNameStep = ({ firstName, setFirstName, businessName, setBusinessName, email, setEmail, setEnableNextButton }) => {

    useEffect(() => { //all of these need to be true in order to be valid.
        setEnableNextButton(!isBlank(firstName) && !isBlank(businessName) && !isBlank(email) && isValidEmail(email));
        // setEnableNextButton(true);
    }, [firstName, businessName, email])

    return (
        <div>
            <InputField
                id="register_first_name"
                type="text"
                label="First Name"
                value={firstName}
                setValue={setFirstName}
                onValidate={(name) => !isBlank(name)}
                />
            
            <InputField
                id="register_business_name"
                label="Business Name"
                value={businessName}
                setValue={setBusinessName}
                onValidate={(name) => !isBlank(name)}
                />
            
            <EmailInputField
                id="register_email"
                email={email}
                setEmail={setEmail}
                />
            {/* <div className="d-flex justify-content-center p-2 m-25 w-100">
                <div className="d-flex">
                    <label htmlFor="register_email">Email</label>
                    <input
                        id="register_email"
                        type="email"
                        value={email}
                        className="form-control"
                        onChange={e => setEmail(e.target.value)}
                        onBlur={e => validateEmailInput(e.target.value)}
                        style={{borderColor: emailIsValid ? "" : 'red'}}
                    />
                </div>
            </div> */}
        </div>
    );
}


export default WhatsYourNameStep;
