import React, {useState} from "react";
import ExpandableTableRow from "./ExpandableTableRow";
import ExpandedBodyRow from "./ExpandedBodyRow";

const ExpandableTableBody = ({publishableJobs}) => { //TODO: how do we handle this..
    //We want our data to be. 
    // City -> Customer Name -> Title -> Invoice/Estimate -> Amount -> Pictures -> Status -> Pictures -> Category/Type of Work? -> Keywords? -> Number of Views -> 
    // Images need to be queried.? But not number of images.
    // I think this needs to come from the invoices controller?
    // JSON object structure?
    // invoice:  { published_job.? }
    // }
    // }

    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleAccordion = (index) => {
      console.log("Toggle accordion: ", index);
      if (expandedIndex === index) {
        setExpandedIndex(null);
      } else {
        setExpandedIndex(index);
      }
    };

    return(
        <tbody data-accordion="table-column">
            {publishableJobs.map((publishableJob, index) => (
                <React.Fragment key={index}>
                    <ExpandableTableRow publishableJob = {publishableJob} index = {index} toggleAccordion={() => toggleAccordion(index)}/>
                    <ExpandedBodyRow publishableJob = {publishableJob} index = {index} isExpanded={expandedIndex === index} />
                </React.Fragment>
            ))}
        </tbody>
    )
}

export default ExpandableTableBody;