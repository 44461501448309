import React, {Component} from "react";

class MessageBubbleSent extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {content, time, from, imageSrc, status} = this.props;
        //TODO: On click we want to display the image from Baguette Box
        const image = ( imageSrc == null || imageSrc.trim() === '' ? <div/> : 
            // This width height should only be happening on image messages..

            <div style={{width: '200px', height: '200px'}}> 
            {/* <div style={{width: '200px', height: '200px'}} className="tz-gallery">  */}

                <a className="lightbox" href={imageSrc}>
                    <img className={`${imageSrc ? 'd-block' : 'd-none'} rounded w-50`}
                        src={imageSrc}
                        style={{width: '100%', height: '100%', objectFit: 'contain'}}
                    />
                </a>
            </div>
        )

        return (
            <div>
                <br></br>
                <div className="mine messages">
                    {image}
                    <div  className={`${content ? 'd-block' : 'd-none'} hsc-message last`} >
                        {content}
                    </div>
                </div>
                <div className="message-info-mine">
                    <span> {from}, {time} </span>
                    <span>
                        {status == "undelivered" ? 
                                <span class="red-x">&#10006;</span>
                            :
                            status == "delivered" ? 
                                <span class="green-checkbox">&#10004;</span>
                            :
                            "" //if not undelivered or delivered, leave blank for now.
                        }
                    </span>
                </div>
                {
                    // status == "undelivered" ? 
                    //     <div>
                    //         {/* TODO: Add undelivered icon */}
                    //         undelivered!
                    //     </div>
                    
                    // : 

                    // status == "delivered" ? 
                    //     <div> 
                    //         {/* TODO: Add delivered icon */}
                    //         delivered! 
                    //     </div>
                    // : 
                    // null
                }
                
            </div>
        );
    }
}
export default MessageBubbleSent;