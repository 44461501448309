import React, { Component } from "react";
import MessageBubbleSent from "./MessageBubbleSent";
import MessageBubbleReceived from "./MessageBubbleReceived";
import ToggleConversations from "./ToggleConversations";
import SendMessageToolbar from "./SendMessageToolbar"
import NewConversationButton from "./NewConversationButton";

class Conversation extends Component{
    constructor(props) {
        super(props);
        this.state = {
            recipientName: props.recipientName,
            recipient: props.recipient,
            context: props.context,
        }
        // this.ajaxGetRecipientNameFromNumber = this.ajaxGetRecipientNameFromNumber.bind(this);
    }

    componentDidMount() {
        $('#chat-window').scrollTop($('#chat-window')[0].scrollHeight)
    }

    componentDidUpdate(){
        $('.phone_mask').mask('(000) 000-0000')
    }


    render(){
        const { context,
                messagesImageUrls,
                businessPhoneNumber,
                recipient, 
                recipientName,
                addNewMessageCallback,
                openNewConversationCallback } = this.props;

        //This is to determine if it's a new conversation thread..
        let recipientElement = null;
        if (recipient == "New Conversation"){
            //This is going to render a field where they type in a phone number to send to.
            //Then on send, it will turn into a normal recipient.
            recipientElement = (<input id="new-phone-number" placeholder="Phone number" className="phone_mask form-control"/>)
        }
        else {
            recipientElement = <h4 id='recipient-display-name'> {recipient} </h4>
        }

        let recipientNameElement = null;
        if (recipientName && recipientName["full_name"] != null && recipientName["id"] != null){
            if (recipientName["customer_id"] != null){
                recipientNameElement = (<a href={`/customers/${recipientName["customer_id"]}`}>{recipientName["full_name"]}</a>);
            }
            else {
                recipientNameElement = (<a href={`/customers/${recipientName["id"]}`}>{recipientName["full_name"]}</a>);
            }
        }
        else {
            recipientNameElement = "";
        }

        return(
            <div>
                <div className="d-flex justify-content-around">
                    <div className="p-3">
                        <ToggleConversations/>
                    </div>
                    <div className="p-2">
                        {recipientElement}
                    </div>
                    <div className="p-2">
                        <h4 id="convo-recipient-name">
                            {recipientNameElement}
                        </h4>
                    </div>
                    <div className="p-2">
                        <NewConversationButton selectNewConversationThreadCallback = {openNewConversationCallback}/>
                    </div>
                </div>
                <div className="d-flex justify-content-center chat" id="chat-window" style={{maxHeight:'35em', overflowY: 'auto'}}>
                    <div className="p-2 w-75 tz-gallery">
                        {context.map((message, index) => (
                            message.sent_from == businessPhoneNumber ?
                                <MessageBubbleSent
                                    content={message.content}
                                    imageSrc={messagesImageUrls[index]}
                                    time={moment(message.created_at).calendar(null, {sameElse: 'MMMM DD, hh:mm a'})}
                                    key={`message-${index}`}
                                    from={message.name}
                                    status={message.status}>
                                </MessageBubbleSent>
                            : 
                                <MessageBubbleReceived
                                    content={message.content}
                                    imageSrc={messagesImageUrls[index]}
                                    time={moment(message.created_at).calendar(null, {sameElse: 'MMMM DD, hh:mm a'})}
                                    key={`message-${index}`}>
                                </MessageBubbleReceived>
                        ))}
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <SendMessageToolbar addNewMessageCallback = {addNewMessageCallback}/>
                </div>
            </div>
        );
    }
}

export default Conversation;