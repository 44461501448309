import React from "react";

const FRPillButton = ({onClick, text, disabled=false}) => {
    return(
        disabled
        ?
            <button className="fr-pill-button-disabled">{text}</button>
        :
            <button className="fr-pill-button" onClick={onClick}>{text}</button>
    )
}

export default FRPillButton;