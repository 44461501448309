import React, { Component, useState, useEffect } from "react";
import ProgressBar from "../shared/ProgressBar";
import FieldRocketLogo from "../shared/FieldRocketLogo";
import FRPillButton from "../shared/Buttons/FRPillButton";
import WhatsYourNameStep from "./steps/WhatsYourNameStep";
import AskUserHighestPrioritySurveyOnRegistration from "./steps/AskUserHighestPrioritySurveyOnRegistration";
import PhoneNumberStep from "./steps/PhoneNumberStep";
import ConfirmAccessCodeStep from "./steps/ConfirmAccessCodeStep";
import DoneStep from "./steps/DoneStep";
import ArrowLeftIcon from 'images/icons/arrow-left.png'; // Adjust the path as per your directory structure
import AppleIcon from 'images/social/apple-Icon-metro.svg'; // Adjust the path as per your directory structure
import AndroidIcon from 'images/social/android-Icon-material.svg'; // Adjust the path as per your directory structure
import HowDidYouHearAboutUs from "./steps/HowDidYouHearAboutUs";

const GetStarted=() => {
    const [registrationStep, setRegistrationStep] = useState(1);
    const [titleText, setTitleText] = useState("Let's get started!");
    const [buttonText, setButtonText] = useState("Register New Account");
    
    
    
    //NOTE: This must match the same userOptions in the AskUserHighestPriority.jsx to make sure the server gets the right info.
    const userSurveyOptions = [
        "Invoicing & Payments", "Estimates & Closing", "Scheduling Customers", "Growth & Marketing", "Other"
    ];
    const [surveyAnswer, setSurveyAnswer] = useState(-1);
    
    const [freeFormSurveyAnswer, setFreeFormSurveyAnswer] = useState("");
    const [nextButtonEnabled, setNextButtonEnabled] = useState(true);

    const[ howDidYouHearAboutUsAnswer, setHowDidYouHearaboutUsAnswer] = useState("");
    
    const LETS_GET_STARTED_STEP = 1;
    const WHATS_YOUR_NAME_STEP = 2;
    const ASK_USER_HIGHEST_PRIORITY_SURVEY_QUESTION = 3;
    const HOW_DID_YOU_HEAR_ABOUT_US_QUESTION = 4;
    const PHONE_NUMBER_STEP = 5;
    const CONFIRM_ACCESS_CODE_STEP = 6;
    const DONE_STEP = 7;

    const [firstName, setFirstName] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [accessCode, setAccessCode] = useState("");
    const [showBackArrow, setShowBackArrow] = useState(false); // for the user to navigate back to the previous step

    // We need to make the "Next" button disabled if they need to fill out fields, 
    // or if a field is incorrect (like email/phone number), etc.
    const [currentFormIsValid, setCurrentFormIsValid] = useState(true);

    const [employeeId, setEmployeeId] = useState(null);
    const [businessId, setBusinessId] = useState(null);
    // const [accessCode, setAccessCode] = useState(null);

    /**
     * Responsible for changing the title text and button text depending on what's being rendered
     */
    useEffect(() => {
        //Only let the user navigate backwards if they've gone past the first page.
        setShowBackArrow(registrationStep > 1);

        switch(registrationStep){
            case LETS_GET_STARTED_STEP:
                setTitleText("Let's get started!");
                setButtonText("Register New Account");
                setCurrentFormIsValid(true);
                break;
            case WHATS_YOUR_NAME_STEP:
                setTitleText("What's your name?");
                setButtonText("Next");
                break;
            case PHONE_NUMBER_STEP:
                setTitleText("What's Your Phone Number?");
                setCurrentFormIsValid(false);
                break;
            case CONFIRM_ACCESS_CODE_STEP:
                registerBusinessAndSendAccessCode();
                //TODO: This is where we need to attempt to do a POST.. in between these two steps.
                //TODO: This is where we will do a POST!
                setTitleText("We Just Texted You a Code.");
                break;
            case ASK_USER_HIGHEST_PRIORITY_SURVEY_QUESTION: 
                setTitleText("What's Your #1 Priority for Using Field Rocket?");
                setCurrentFormIsValid(false);
                break;
            case HOW_DID_YOU_HEAR_ABOUT_US_QUESTION: 
                setTitleText("How Did You Hear About Field Rocket?");
                setCurrentFormIsValid(false);
                break;                
            case DONE_STEP:
                verifyAccessCodeAndFinishRegistration(); // This is where we validate the access code.. or at least do a POST to the server.
                setTitleText("Done, welcome!");
                break;
        }
    }, [registrationStep])

    const getCurrentStepComponent = () => {
        switch(registrationStep){
            case LETS_GET_STARTED_STEP:
                return (
                    <React.Fragment>
                        <div className="d-flex justify-content-center align-items-center" style={{height:"200px"}}>
                            <FieldRocketLogo/>
                            <h1 className=""> <i> Field Rocket </i> </h1>
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={{height:"50px", margin: "15px"}}>
                            <button className="mobile-download-button"
                                style={{borderRadius: "12px", backgroundColor: "#ede8e8", padding: "15px", margin: "15px", border: "none"}}
                                onClick={() => window.location='https://apps.apple.com/us/app/field-rocket/id1602587569'}
                                > 
                                <img style={{width: 31, height: 31, margin: 5}} src={AppleIcon} alt="" />
                                Download iPhone </button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={{height:"50px", margin: "15px"}}>
                            <button className="mobile-download-button"
                                style={{borderRadius: "12px", backgroundColor: "#ede8e8", padding: "15px", margin: "15px", border: "none"}}
                                onClick={() => window.location='https://play.google.com/store/apps/details?id=com.getservicerocket.mobile&hl=en_US&gl=US'}
                            > 
                            <img style={{width: 31, height: 31, margin: 5}} src={AndroidIcon} alt="" />
                            Download Android </button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center" style={{height:"100px"}}>
                            <h2 className=""> <i> Or </i> </h2>
                        </div>s
                    </React.Fragment>
                );
            case WHATS_YOUR_NAME_STEP:
                return (
                    <WhatsYourNameStep 
                        firstName={firstName} 
                        setFirstName={setFirstName}
                        businessName={businessName} 
                        setBusinessName={setBusinessName}
                        email={email} 
                        setEmail={setEmail}
                        setEnableNextButton={setCurrentFormIsValid}
                        />
                );
            case ASK_USER_HIGHEST_PRIORITY_SURVEY_QUESTION:
                return (
                    <AskUserHighestPrioritySurveyOnRegistration
                        surveyAnswer={surveyAnswer}
                        setSurveyAnswer={setSurveyAnswer}
                        freeFormSurveyAnswer={freeFormSurveyAnswer}
                        setFreeFormSurveyAnswer={setFreeFormSurveyAnswer}
                        setEnableNextButton={setCurrentFormIsValid}
                    />
                );       
            case HOW_DID_YOU_HEAR_ABOUT_US_QUESTION:
                return (
                    <HowDidYouHearAboutUs
                        howDidYouHearAboutUsAnswer={howDidYouHearAboutUsAnswer}
                        setHowDidYouHearaboutUsAnswer={setHowDidYouHearaboutUsAnswer}
                        setEnableNextButton={setCurrentFormIsValid}
                    />
                );                                
            case PHONE_NUMBER_STEP:
                return (
                    <PhoneNumberStep 
                        phone={phone} 
                        setPhone={setPhone}
                        setEnableNextButton={setCurrentFormIsValid}
                    />
                );
            case CONFIRM_ACCESS_CODE_STEP:
                return (
                    <ConfirmAccessCodeStep 
                        accessCode={accessCode} 
                        setAccessCode={setAccessCode} 
                        setInputIsValidated={setInputIsValidated}
                        />
                );

            case DONE_STEP:
                return (
                    <DoneStep email={email} />
                );
            default:
                return <div>Invalid Step</div>;
        }
    }

    const onBackArrowPressed = () => {
        if (registrationStep > 1){ // don't let the registration step get below 1
            setRegistrationStep(registrationStep - 1);
        }
    }

    const setInputIsValidated=(isValid, invalidMessage = null)=>{
        setIsInputValid(isValid);
        setInvalidInputMessage(invalidMessage);
    }

    /**
     * This request does a POST request to the server to create 
     * the business entity. 
     * 
     * If there is an error, then we need to display the error
     * very clearly to the user so they can resolve it, and take them back 
     * to the appropriate step.
     * 
     */
    const registerBusinessAndSendAccessCode = () => {
        //POST /businesses - to the right route to create a business and trigger an access code to be sent via Twilio.

        let readableSurveyAnswer = userSurveyOptions[surveyAnswer]

        const body = {
            business: {
              phone: phone.replace(/[- )(]/g,''),
              name: businessName,
              survey_answers: readableSurveyAnswer + " - " + freeFormSurveyAnswer,
              attribution_source: howDidYouHearAboutUsAnswer + " - Web App",
              email,
              employees_attributes: [{ phone: phone.replace(/[- )(]/g,''), first_name: firstName, email }],
            },
          };
        
          $.ajax({
            url: "/businesses.json",
            type: "POST", // Equivalent to method in fetch API
            contentType: "application/json", // Sets the Content-Type header
            data: JSON.stringify(body), // The data to send in the request
            success: function(response) {
                console.log(response);
                setEmployeeId(response.first_employee_created_id); // We set these here, and then we use this when verifying the access code.
                setBusinessId(response.id);
            },
            error: function(jqXHR, textStatus, errorThrown) {
              // This function is called if the request fails.
              // 'jqXHR' is the jQuery XMLHttpRequest object.
              // 'textStatus' is a string describing the type of error.
              // 'errorThrown' is an optional exception object, if one occurred.
              console.log("Error while registering business: " + errorThrown);
              return errorThrown;
            }
          });
    }

    /**
     * This request does a POST request to verify the access code and finish activating our user's account
     * 
     * TODO: If there is an error, then we need to display the error
     * very clearly to the user so they can resolve it, and take them back 
     * to the appropriate step.
     * 
     */
    const verifyAccessCodeAndFinishRegistration = () => {
        const body = {
          access_code: "990099",
          business_id: businessId
        };
      
        $.ajax({
          url: "/employees/" + employeeId + "/verify_access_code.json",
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify(body),
          success: function(response) {
            console.log("Verification Successful", response);
            //TODO: Fix. Push them through without verification
            // Handle success scenario (e.g., update UI, redirect, etc.)
          },
          error: function(xhr, status, error) {
            console.error("Verification Failed", xhr, status, error);
            // Handle error scenario
          }
        });
      };    

    return (
        <React.Fragment>
            <div className="get-started" style={{height: "100%", width: "100%", display: "flex", flexDirection:"column", justifyContent: "space-between"}}>
                <ProgressBar
                    bgcolor="#ff5a5a"
                    progress={`${20*registrationStep}`}
                    height={15}
                />
                <div className="d-flex justify-content-center">
                    {showBackArrow ? 
                        <div className="p-2">
                            <button onClick={onBackArrowPressed} style={{ background: 'none', border: 'none' }}>
                                <img style={{width: 31, height: 31}} src={ArrowLeftIcon} alt="Back" />
                            </button>
                        </div>
                    : null }
                        <div className="p-2">
                            <h3 className="" style={{height:"150px"}}> {titleText} </h3>
                        </div>
                </div>
                {getCurrentStepComponent()}
                
                {registrationStep != DONE_STEP && 
                <div className="d-flex justify-content-center">
                    <FRPillButton disabled={!currentFormIsValid} text={buttonText} onClick={() => setRegistrationStep(registrationStep+1)}/>
                </div>
                }
                
                <div style={{height:"200px"}}>
                    <div className="d-flex justify-content-center" style={{margin: "10px", padding: "10px"}}>
                        <a href="/employees/sign_in" className="fr-link"> Already have an account?</a>
                    </div>
                    <div className="d-flex justify-content-center" style={{margin: "10px", padding: "10px"}}>
                        <a href="https://fieldrocket.us/eula-privacy-policy/"> By proceeding, you acknowledge you've read and agree to our EULA & Privacy Policy.</a>
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
}

export default GetStarted;